import React, { useEffect, useState } from 'react';

const ErrorPage = props => {

    return (
        <div className="row" >
            <div className="cover-container">
                <div className="innercover providerImageDiv">
                    <div className="col-md-12 col-xs-12">
                        <h1><i className="fa fa-exclamation-triangle" style={{fontSize:'30px'}}></i> Something Went Wrong!</h1>
                        <p className="margin-top-20"> An error occured while accessing your information.</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ErrorPage;