import React, { useEffect } from 'react';
import { useLocation, useNavigate } from "react-router-dom";


function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
}

const FinishEnrollment = props => {
    const query = useQuery();
    const navigate = useNavigate();

    useEffect(() => {
        window.location.href = `${process.env.REACT_APP_ENROLLMENT_PORTAL_URL}?id=${query.get('id')}&hash=${query.get('hash')}`;
    }, []);


    return (
        <div>
            <p>Redirecting to Arcadia Authentication Page</p>
        </div>
    )
}

export default FinishEnrollment;