import './App.css';
import React from "react";
import CreateCredentials from './CreateCredentials';
import FinishEnrollment from './FinishEnrollment';
import ErrorPage from './ErrorPage';

import {
    BrowserRouter as Router,
    Route,
    Routes
} from "react-router-dom";

function App() {
    return (
        <div className="App">
            <Router>
                <Routes>
                    <Route path="/finishEnrollment" element={<FinishEnrollment />} />
                    <Route path="/error" element={<ErrorPage />} />
                    <Route path="/" element={<CreateCredentials />} />
                </Routes>
            </Router>
        </div>
    );
}

export default App;
