import React, { useEffect } from "react";
import { useConnect } from '@arcadia-eng/connect-react';
import {
    useLocation
} from "react-router-dom";

function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
}

const CreateCredentials = props => {
    let query = useQuery();

    const onClose = ({ status }) => {
        switch (status) {
            case 'verified':
                window.location.replace(`${query.get("returnUrl")}`);
                return;
            case 'rejected':
                return;
            case 'timed_out':
                return;
            case 'pending':
                return;
            case 'no_submit':
                open(config);
                return;
            case 'error':
                return;
            default:
                open(config);
                return;
        }
    }

    const config = {
        connectToken: query.get('token'),
        callbacks: {
            onClose: onClose
        }
    };

    const [{ loading, error }, open] = useConnect();

    useEffect(() => {
        open(config)
    }, []);

    return (
        <div onClick={() => open(config)}>
            Please Click Here to Confirm Your Arcadia Credentials
        </div>
    );
};

export default CreateCredentials;